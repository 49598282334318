import React, { useContext, useState, useCallback } from "react";

import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import MuiPhoneNumber from 'material-ui-phone-number'
// import { unstable_Box as Box } from "@material-ui/core/Box";


//import TextField from '@material-ui/core/TextField';
import Fab from "@material-ui/core/Fab";
import { FyneContext } from "../../FyneContext";
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    color: 'red'
  },
});


export const IntroPlumber = ({...props}) => {// = class Intro1 extends Component {
  const config = useContext(FyneContext);
  console.log('PlumberLanding Intro1', {props,config});

  const classes = useStyles();
  console.log('PlumberLanding classes', {classes});

  const [ values, setValues ] = useState({phone:''});

  const handleChange = useCallback((prop, value) => {
    //console.log( 'Form Updated', prop, value, values );
    window.Hubster && window.Hubster.dispatch('form-updated', { values: { prop, value } })
    const update = { [prop]: value };
    const newValues = { ...values, ...update };
    //console.log( 'Form Updated', prop, value, {values, newValues} );
    setValues(newValues);
  }, [ values ]);

  let state = {};
  //render() {
    return (
      <section className="section section-intro1 section-intro3" id="intro3">
        <div className="container">
          <Grid container spacing={8} justify="center">
            <Grid item md={6}>
              <h1 className="section-intro1__title">
                Fyne Plumbers
              </h1>
              <div className="section-intro1__subtitle">
                Not sure about Pro? Try trial first!
              </div>

              <div className="section-intro1__list">
                <div className="section-intro1__list__item text-muted">
                  <Icon color="secondary">check</Icon> Unlimited Projects.
                </div>
                <div className="section-intro1__list__item text-muted">
                  <Icon color="secondary">check</Icon> Unlimited Team Members.
                </div>
                <div className="section-intro1__list__item text-muted">
                  <Icon color="secondary">check</Icon> Unlimited Disk Space.
                </div>
              </div>

              <form noValidate autoComplete="off">

                <div className="subscribe-input">
                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item xs={12} md={6}>
                      <MuiPhoneNumber value={values.phone || ''}
                        //onChange={event=>handleChange('phone',event.target.value)}
                        onChange={value=>handleChange('phone',value)} 
                        defaultCountry={'gb'}
                        type="tel" id="phone" label="Phone"
                        autoComplete="tel"
                        //onBlur={handleBlur}
                        //error={!!touched['phone'] && !!errors['phone']} helperText={errors['phone'] || ''}
                        fullWidth
                        margin="dense"
                        className={classes.fullWidth}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} align="right">
                      <Fab
                        variant="extended"
                        size="large"
                        color="secondary"
                        aria-label="Buy"
                        className={(
                          classes.fullWidth,
                          "btn-action"
                        )}
                      >
                        {/* <Box r={20}> */}
                          <PhoneCallbackIcon/>
                        {/* </Box> */}
                        Call me back
                      </Fab>
                    </Grid>
                  </Grid>
                </div>
                
              </form>

            </Grid>
            <Grid item md={6}>
              <div className="intro3__product">
                <img
                  src="https://res.cloudinary.com/fynewo/image/upload/v1589278411/landing/plumber.jpg"
                  alt=""
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  //}
}

export default IntroPlumber;
